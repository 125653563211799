//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { initWx } from '../components/minxin'
import selectLabelPer from '../components/selectLabelPer.vue'
import selectPerson from '../components/selectPerson'
import createBillMsg from './components/createBillMsg.vue'
import mobileBillSendList from './components/list.vue'
export default {
    components: {
        createBillMsg,
        selectPerson,
        mobileBillSendList,
        selectLabelPer,
    },
    mixins: [initWx],
    data() {
        return {
            active: 1,
            tabs: [
                {
                    title: '新增',
                    id: 1,
                },
                {
                    title: '待接收',
                    id: 2,
                },
                {
                    title: '已完成',
                    id: 3,
                },
                {
                    title: '草稿箱',
                    id: 4,
                },
            ],
        }
    },
    methods: {
        handleChange(evt) {
            this.active = evt
            this.setSwiper(evt)
            this.$refs.mobileBillContent.scrollTop = 0
        },
        showSelectDialog(key) {
            this.$refs.selectPerson.open({ key })
        },
        selectTodoMsg({ type, data }) {
            if (type === 'book') {
                this.$refs.createBillMsg.choose_Persons(data.key)
            } else if (type === 'label') {
                this.$refs.selectLabelPer.open({ ...data })
            } else if (type === 'labelResult') {
                const { result, key } = data
                this.$refs.createBillMsg.setLabelPerson({ result, key })
            }
        },
        changeTab(index) {
            this.$refs.mobileTable[index - 1].refresh()
            this.handleChange(index)
        },
        setSwiper(index) {
            this.$refs.swipe.swipeTo(index)
        },
    },
}
