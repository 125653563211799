//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { delDocumentBill, documentBillDispatchPage } from '@/api/modular/fileStream/documentManage'
import item from '../../components/item'
import searchBox from '../../components/searchBox'
export default {
    components: {
        item,
        searchBox,
    },
    props: {
        index: {
            type: Number,
            default: 0,
        },
    },
    watch: {
        index: {
            handler(val) {
                this.active = val === 2 ? 1 : 2
                this.queryParam.approvalTab = val + 1
                this.queryParam.status = this.active
                this.refresh()
            },
            immediate: true,
        },
    },
    data() {
        return {
            loading: false,
            finished: false,
            queryParam: { documentType: 2, approvalTab: 0 },
            active: 2,
            pageNo: 1,
            billList: [],
            pageSize: 10,
        }
    },
    methods: {
        onLoad() {
            this.loading = true
            this.pageNo++
            this.getBillList()
        },
        preView({ id }) {
            this.$router.push({ name: 'mobileBillDetail', query: { id } })
        },
        refresh() {
            this.pageNo = 1
            this.billList = []
            this.pageSize = 10
            this.getBillList()
        },
        singleDelete({ id }) {
            delDocumentBill([
                {
                    id,
                },
            ])
                .then((res) => {
                    if (res.code === 200) {
                        this.$message.success('刪除成功')
                        this.refresh()
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        deal({ id }) {
            this.$router.push({ name: 'showMobileApproval', query: { id, todo: 'dealWith' } })
        },
        edit(record) {
            this.$router.push({ name: 'mobileBillEdit', query: { id: record.id } })
        },
        addApproval({ id }) {
            this.$router.push({ name: 'mobileCreateApproval', query: { id } })
        },
        onSearch() {
            this.refresh()
        },
        see(record) {
            this.$router.push({ name: 'showMobileApproval', query: { id: record.id } })
        },
        getBillList() {
            const formatDraftId = (id) => {
                if (!id) return []
                return JSON.parse(id)
            }
            documentBillDispatchPage(
                Object.assign(
                    {
                        pageNo: this.pageNo,
                        pageSize: this.pageSize,
                    },
                    this.queryParam
                )
            )
                .then((res) => {
                    const data = { ...res.data }
                    data.rows = data.rows.map((ele) => {
                        ele.draftId = formatDraftId(ele.draftId)
                        return ele
                    })
                    this.finished = data.rows.length < this.pageSize
                    this.billList = [...this.billList, ...data.rows]
                })
                .finally(() => {
                    this.loading = false
                })
        },
    },
}
